import "./big-home-cta.scss";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

function BigHomeCta() {
    const history = useHistory();
  return (
    <div className="BigHomeCta">
        <Button variant="contained" className="cta" onClick={()=>history.push('/mint')}>
          <h1>Mint your Budheads!</h1>
        </Button>
    </div>
  );
}

export default BigHomeCta;
