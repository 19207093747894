import "./roadmap.scss";

function Roadmap() {
  return (
    <div className="Roadmap">
        <h2>ROADMAP</h2>
      <div className="roadmap-items">
        {/* <line className="line"></line> */}
        <div className="roadmap-item">
          <div className="icon">
            <img
              className="frame"
              src="images/roadmap/10.png"
              alt="roadmap-symbol"
            />
          </div>
          <h3>10% Budheads Sold</h3>
          <p>Giveaway of 3 1/1 dope artwork from the Budheads universe for owners</p>
        </div>
        <div className="roadmap-item">
          <div className="icon">
            <img
              className="frame"
              src="images/roadmap/25.png"
              alt="roadmap-symbol"
            />
          </div>
          <h3>25% Budheads Sold</h3>
          <p>Giveaway of 5 1/1 dope artwork from the Budheads universe for owners</p>
        </div>
        <div className="roadmap-item">
          <div className="icon">
            <img
              className="frame"
              src="images/roadmap/50.png"
              alt="roadmap-symbol"
            />
          </div>
          <h3>50% Budheads Sold</h3>
          <p>Giveaway of 10 1/1 dope artwork from the Budheads universe + ETH for owners</p>
        </div>
        <div className="roadmap-item">
          <div className="icon">
            <img
              className="frame"
              src="images/roadmap/75.png"
              alt="roadmap-symbol"
            />
          </div>
          <h3>75% Budheads Sold</h3>
          <p>Giveaway of 15 1/1 dope artwork from the Budheads universe + ETH for owners</p>
        </div>
        <div className="roadmap-item">
          <div className="icon">
            <img
              className="frame"
              src="images/roadmap/100.png"
              alt="roadmap-symbol"
            />
          </div>
          <h3>100% Budheads Sold</h3>
          <p>Development of the new collection expanding the Budheads universe + Airdrop for owners</p>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
