import "./loading.scss";

function Loading() {
  return (
    <div className="Loading">
        <h2>Loading <span className="outer"><img src="images/loading-accent.png"/></span></h2>
      <video autoPlay={true} loop={true} playsInline muted>
        <source src="images/loading.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

export default Loading;
