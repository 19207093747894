import "./what-is-budheads.scss";

function WhatIsBudheads() {
  return (
    <div className="WhatIsBudheads">
      <h2>What is Budheads?</h2>
      <div className="side-by-side">
      <p>
        Budheads is an NFT collection of programmatically, randomly generated
        NFTs getting high on Polygon. The 1st generation consists of 4,200
        randomly created from over 1 million possible options. Each Budhead
        grants 100% copyright for their current owner. If you own a Budhead, you
        can use it for whatever you want to.
      </p>
      <img src="images/showcase.gif"/>
      </div>
    </div>
  );
}

export default WhatIsBudheads;
