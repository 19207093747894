import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./Team.scss";

const Team = () => {
  return (
    <div className="team-container">
      <div className="container">
        <h2>The Team</h2>
        <p>These are the buds behind Budheads!</p>
        <div className="cards-container">
          <Card className="card">
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                className="card-image"
                image="images/kayna.jpeg"
                title="Kayna profile picture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Kaynã Rodrigues
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Problem solver monke man on all things technology and
                  design-related.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                href="https://www.linkedin.com/in/kayna-oliveira/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </Button>
              <Button
                size="small"
                color="primary"
                href="https://kayna.space"
                target="_blank"
                rel="noreferrer"
              >
                Website
              </Button>
            </CardActions>
          </Card>
          <Card className="card">
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                className="card-image"
                image="images/tiago.jpg"
                title="Tiago profile picture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Tiago Amaral
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Crypto enthusiast. Love games and study about the impacts of
                  technology on society.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                href="https://www.linkedin.com/in/tiago4maral/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </Button>
            </CardActions>
          </Card>
          <Card className="card">
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                className="card-image"
                image="images/dibis.jpeg"
                title="Diniz profile picture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Dibis
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Basically a gorilla who loves to draw.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                href="https://twitter.com/dibisonether"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Team;
