import "./how-much.scss";

function HowMuch() {
  return (
    <div className="HowMuch">
      {/* <h2>I’m ready to get high with the Budheads. How much?</h2>
      <p>
        Budheads are priced at a flat rate of 0.0042 ETH. Yeah, that’s right.
        Only 0.0042 ETH.
      </p> */}
      <img src="images/post_preco.gif"/>
    </div>
  );
}

export default HowMuch;
