import { Biconomy } from "@biconomy/mexa";
import { BehaviorSubject, filter, withLatestFrom } from "rxjs";
import { getTransactionReceiptMined } from "./status-service";
import MetaMaskOnboarding from "@metamask/onboarding";
import { Hyphen, SIGNATURE_TYPES, RESPONSE_CODES } from "@biconomy/hyphen";
import Web3Modal from "web3modal";
const contractABI = require("./Budheads.json");
const forwarderABI = require("./BiconomyForwarder.json");
const LiquidityPoolManager = require("./LiquidityPoolManager.json");
const rootChainManagerABI = require("./RootChainManager.json");
const currencyABI = require("./WETH.json");
const Web3 = require("web3");
const axios = require("axios");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

//const contractOwnerAddress = "0x542FaAd1179D57c0b5D88Af0e7a3fbA3ed15412B";
const contractOwnerAddress = "0x542FaAd1179D57c0b5D88Af0e7a3fbA3ed15412B";
var contractAddressDev = "0x8622477F57031b1FC0082fc402bD60A34bbD6479";
var contractAddressProd = "0x6b9556B96a4B36542e277E9D860535eB010b8672";
var currencyAddressProd = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
var currencyAddressDev = "0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa";
var forwarderAddressDev = "0x9399BB24DBB5C4b782C70c2969F58716Ebbd6a3b";
var forwarderAddressProd = "0x9399BB24DBB5C4b782C70c2969F58716Ebbd6a3b";
var liquidityPoolManagerProd = "0xF78765bd14B4E8527d9E4E5c5a5c11A44ad12F47";
var liquidityPoolManagerDev = "0xBD435D6dB65ED030bF2eD398B311d70210D452Cd";
const alchemyKeyDev = process.env.REACT_APP_ALCHEMY_KEY_DEV; // Mumbai (test net)
const alchemyKeyProd = process.env.REACT_APP_ALCHEMY_KEY_PROD; // Main net (polygon)
const alchemyKeyParentDev = process.env.REACT_APP_ALCHEMY_KEY_PARENT_DEV; // Main net (polygon)
const alchemyKeyParentProd = process.env.REACT_APP_ALCHEMY_KEY_PARENT_PROD; // Main net (polygon)
const budPrice = 42 * 10 ** 14;
const polygonTestNetData = {
  chainId: "0x13881", // A 0x-prefixed hexadecimal string
  chainName: "Polygon Testnet",
  nativeCurrency: {
    name: "Matic Token",
    symbol: "MATIC", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};
const polygonMainNetData = {
  chainId: "0x89", // A 0x-prefixed hexadecimal string
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "Matic Token",
    symbol: "MATIC", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["https://polygon-rpc.com/"],
  blockExplorerUrls: ["https://polygonscan.com"],
};
const goerliTestNetData = {
  chainId: "0x5", // A 0x-prefixed hexadecimal string
  chainName: "Goerli",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["http://goerli.prylabs.net/"],
  blockExplorerUrls: ["https://goerli.etherscan.io/"],
};
const MainNetData = {
  chainId: "0x1", // A 0x-prefixed hexadecimal string
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["https://main-light.eth.linkpool.io/"],
  blockExplorerUrls: ["https://etherscan.io"],
};
const localNet = {
  chainId: "0x1691", // A 0x-prefixed hexadecimal string
  chainName: "Local testnet",
  nativeCurrency: {
    name: "Matic Token",
    symbol: "MATIC", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["http://127.0.0.1:8545"],
};

const isProd = process.env.REACT_APP_IS_PROD === "true";
const API_ROOT = process.env.REACT_APP_API_ROOT;
export const netBeingUsed = isProd ? polygonMainNetData : polygonTestNetData;
export const parentNetBeingUsed = isProd ? MainNetData : goerliTestNetData;
const forwarderAddress = isProd ? forwarderAddressProd : forwarderAddressDev;
const contractAddress = isProd ? contractAddressProd : contractAddressDev;
const fromChainId = isProd ? "1" : "5";
const toChainId = isProd ? "137" : "80001";
const liquidityPoolManager = isProd
  ? liquidityPoolManagerProd
  : liquidityPoolManagerDev;
const web3parent = createAlchemyWeb3(
  isProd ? alchemyKeyParentProd : alchemyKeyParentDev
);
//const web3 = new Web3(new Web3.providers.HttpProvider("http://localhost:8545"));
const polygonProvider = new Web3.providers.WebsocketProvider(
  isProd ? alchemyKeyProd : alchemyKeyDev
);
const biconomy = new Biconomy(polygonProvider, {
  apiKey: isProd
    ? process.env.REACT_APP_BICONOMY_API_KEY_PROD
    : process.env.REACT_APP_BICONOMY_API_KEY_DEV,
  debug: isProd,
});
const biconomySubject = new BehaviorSubject();
const providerSubject = new BehaviorSubject();
const hyphenSubject = new BehaviorSubject();
let hyphen;
const web3 = new Web3(biconomy);
const currencyAddress = isProd ? currencyAddressProd : currencyAddressDev;
const providerOptions = {
  /* walletconnect: {
    package: window.WalletConnectProvider?.default,
    options: {
      // Mikko's test key - don't copy as your mileage may vary
      infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
    }
  },

  fortmatic: {
    package: window.Fortmatic,
    options: {
      // Mikko's TESTNET api key
      key: "pk_test_391E26A3B43A3350"
    }
  } */
};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

export const setupProvider = async () => {
  if (web3Modal.cachedProvider) {
    window.provider = await web3Modal.connect();
    providerSubject.next(window.provider);
  }
};
export const setupContract = async () => {
  if (!window.currencyContract) {
    window.currencyContract = await new web3.eth.Contract(
      currencyABI,
      currencyAddress
    );
  }
  if (!window.contract) {
    window.contract = await new web3.eth.Contract(
      contractABI.abi,
      contractAddress
    );
  }
  if (!window.forwarderContract) {
    window.forwarderContract = await new web3.eth.Contract(
      forwarderABI.abi,
      forwarderAddress
    );
  }
  biconomy
    .onEvent(biconomy.READY, async () => {
      biconomySubject.next(biconomy);
    })
    .onEvent(biconomy.ERROR, (error, message) => {
      // Handle error while initializing mexa
    });
  initHyphen();
};

export const initHyphen = async () => {
  biconomySubject
    .pipe(withLatestFrom(providerSubject))
    .asObservable()
    .subscribe(async () => {
      if (window.provider) {
        hyphen = new Hyphen(polygonProvider, {
          debug: isProd ? false : true, // If 'true', it prints debug logs on console window
          environment: isProd ? "prod" : "test", // It can be "test" or "prod"
          onFundsTransfered: (data) => {
            // Optional Callback method which will be called when funds transfer across
            // chains will be completed
          },
        });
        await hyphen?.init();
        hyphenSubject.next(hyphen);
      }
    });
};

export const getCurrentWalletConnected = async (
  shouldRequest = false,
  shouldForceRequest = false
) => {
  if (window.provider) {
    try {
      const reRequestParams = {
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
      };
      const requestParams = { method: "eth_requestAccounts" };
      const fetchParams = {
        method: "eth_accounts",
      };
      const addressArray = await window.provider.request(
        shouldForceRequest
          ? reRequestParams
          : shouldRequest
          ? requestParams
          : fetchParams
      );

      if (addressArray.length > 0) {
        return addressArray[0];
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  } else {
    return "";
  }
};

export const estimationInGwei = async (quantity) => {
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: contractOwnerAddress, // must match user's active address.
    data: window.contract.methods
      .mintToken(contractOwnerAddress, quantity, getRandomInt())
      .encodeABI(), //make call to NFT smart contract
  };
  return (await web3.eth.estimateGas(transactionParameters)) * 2;
};

function getRandomInt() {
  let min = 0;
  let max = Math.pow(2, 16);
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const mint = async (quantity) => {
  const resultObservable = new BehaviorSubject();
  try {
    const selectedAddress = await getCurrentWalletConnected();
    // Initialize constants
    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "verifyingContract", type: "address" },
      { name: "salt", type: "bytes32" },
    ];
    const metaTransactionType = [
      { name: "nonce", type: "uint256" },
      { name: "from", type: "address" },
      { name: "functionSignature", type: "bytes" },
    ];
    // replace the chainId 42 if network is not kovan
    let domainData = {
      name: "Budheads",
      version: "1",
      verifyingContract: contractAddress,
      // converts Number to bytes32. pass your chainId instead of 42 if network is not Kovan
      salt: "0x" + (isProd ? 137 : 80001).toString(16).padStart(64, "0"),
    };
    let nonce = await window.contract.methods.getNonce(selectedAddress).call();
    // Create your target method signature.. here we are calling setQuote() method of our contract
    let functionSignature = window.contract.methods
      .mintToken(selectedAddress, quantity, getRandomInt())
      .encodeABI();
    let message = {};
    message.quantity = quantity;
    message.from = selectedAddress;
    message.contractAddress = contractAddress;
    message.nonce = parseInt(nonce);
    message.functionSignature = functionSignature;

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType,
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message,
    });
    // NOTE: Using walletWeb3 here, as it is connected to the wallet where user account is present.
    // Get the EIP-712 Signature and send the transaction
    window.provider.sendAsync(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [selectedAddress, dataToSign],
      },
      function (error, response) {
        if (error) {
          resultObservable.next({ error });
        } else {
          // Check github repository for getSignatureParameters helper method
          let { r, s, v } = getSignatureParameters(response.result);
          let tx = window.contract.methods
            .executeMetaTransaction(selectedAddress, functionSignature, r, s, v)
            .send({ from: selectedAddress });

          tx.on("transactionHash", function (hash) {
            resultObservable.next({ hash });
            // Handle transaction hash
          })
            .once("confirmation", function (confirmationNumber, receipt) {
              // Handle confirmation
            })
            .on("error", function (error) {
              resultObservable.next({ error });
              // Handle error
            });
        }
      }
    );
  } catch (error) {
    resultObservable.next({ error });
  }
  return resultObservable.pipe(filter((el) => !!el)).asObservable();
};

export const approve = async (quantity) => {
  const resultObservable = new BehaviorSubject();
  try {
    const selectedAddress = await getCurrentWalletConnected();
    // Initialize constants
    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "verifyingContract", type: "address" },
      { name: "salt", type: "bytes32" },
    ];
    const metaTransactionType = [
      { name: "nonce", type: "uint256" },
      { name: "from", type: "address" },
      { name: "functionSignature", type: "bytes" },
    ];
    // replace the chainId 42 if network is not kovan
    let domainData = {
      name: "Wrapped Ether",
      version: "1",
      verifyingContract: currencyAddress,
      // converts Number to bytes32. pass your chainId instead of 42 if network is not Kovan
      salt: "0x" + (isProd ? 137 : 80001).toString(16).padStart(64, "0"),
    };
    const nonce = await window.currencyContract.methods
      .getNonce(selectedAddress)
      .call();

    const amount = quantity * budPrice;
    // Create your target method signature.. here we are calling setQuote() method of our contract
    let functionSignature = window.currencyContract.methods
      .approve(contractAddress, amount.toString())
      .encodeABI();
    let message = {};
    message.quantity = quantity;
    message.approvedETH = amount / 10 ** 18;
    message.spender = contractAddress;
    message.from = selectedAddress;
    message.nonce = parseInt(nonce);
    message.functionSignature = functionSignature;

    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType,
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message,
    });
    // NOTE: Using walletWeb3 here, as it is connected to the wallet where user account is present.
    // Get the EIP-712 Signature and send the transaction
    window.provider.sendAsync(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [selectedAddress, dataToSign],
      },
      async (error, response) => {
        if (error) {
          resultObservable.next({ error });
        } else {
          // Check github repository for getSignatureParameters helper method
          let { r, s, v } = getSignatureParameters(response.result);
          let tx = window.currencyContract.methods
            .executeMetaTransaction(selectedAddress, functionSignature, r, s, v)
            .send({ from: selectedAddress });
          tx.on("transactionHash", function (hash) {
            resultObservable.next({ hash });
            // Handle transaction hash
          })
            .on("confirmation", function (confirmationNumber, receipt) {
              // Handle confirmation
              // Never enters here, for some reason
            })
            .on("error", function (error) {
              resultObservable.next({ error });
              // Handle error
            });
        }
      }
    );
  } catch (error) {
    resultObservable.next({ error });
  }
  return resultObservable.pipe(filter((el) => !!el)).asObservable();
};

export const approveBridgeTransfer = async (quantity) => {
  const selectedAddress = await getCurrentWalletConnected();
  const amount = quantity * budPrice;
  let depositTx = await hyphen.deposit({
    sender: selectedAddress,
    receiver: selectedAddress,
    tokenAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    depositContractAddress: liquidityPoolManager,
    amount: amount.toString(), //Amount to be transferred. Denoted in smallest unit eg in wei",
    fromChainId: fromChainId, // chainId of fromChain
    toChainId: toChainId, // chainId of toChain
    useBiconomy: false, // OPTIONAL boolean flag specifying whether to use Biconomy for gas less transaction or not
  });

  return await depositTx;
};

export const getBridgeLimit = async () => {
  await hyphenSubject.asObservable().subscribe(async () => {
    if (hyphen) {
      const info = await hyphen.getPoolInformation(
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        fromChainId,
        toChainId
      );
      const { minDepositAmount, maxDepositAmount } = info;
      return { minDepositAmount, maxDepositAmount };
    }
  });
};

export const switchToCorrectNetwork = async () => {
  try {
    await window.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: netBeingUsed.chainId }],
    });
    return true;
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.provider.request({
          method: "wallet_addEthereumChain",
          params: [netBeingUsed],
        });
        return true;
      } catch (addError) {
        return false;
      }
    }
    return false;
  }
};

export const switchToParentNetwork = async () => {
  try {
    await window.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: parentNetBeingUsed.chainId }],
    });
    return true;
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.provider.request({
          method: "wallet_addEthereumChain",
          params: [parentNetBeingUsed],
        });
        return true;
      } catch (addError) {
        return false;
      }
    }
    return false;
  }
};

export const isNetworkCorrect = (id = null) => {
  const netWorkToCheck = id
    ? web3.utils.hexToNumber(id).toString()
    : window.provider.networkVersion;
  return (
    netWorkToCheck === web3.utils.hexToNumber(netBeingUsed.chainId).toString()
  );
};

export const getAddressLabel = (addr) => {
  if (addr.length) {
    const length = addr.length;
    return addr
      .slice(0, 6)
      .concat("...")
      .concat(addr.slice(length - 5, length));
  } else {
    return "";
  }
};

export const lauchWalletConnection = async () => {
  /* if (MetaMaskOnboarding.isMetaMaskInstalled()) {
    const address = await getCurrentWalletConnected(true);
    await switchToParentNetwork();
    return address;
  } */
  const provider = await web3Modal.connect();
  window.provider = provider;
  const address = await getCurrentWalletConnected(true);
  return address;
};

export const forceWalletConnection = async () => {
  if (MetaMaskOnboarding.isMetaMaskInstalled()) {
    const address = await getCurrentWalletConnected(true, true);
    //await switchToParentNetwork();
    return address;
  }
};

export const getTokensOfOwner = async (address = null) => {
  const selectedAddress = await getCurrentWalletConnected();
  if (!selectedAddress) {
    return "";
  }
  const tokensOfOwnerCall = await window.contract.methods
    .tokensOfOwner(address ? address : selectedAddress)
    .call();
  return tokensOfOwnerCall;
};

export const getSaleState = async () => {
  return await window?.contract?.methods.getSaleState().call();
};

export const loadNFTs = async () => {
  const selectedAddress = await getCurrentWalletConnected();
  if (selectedAddress) {
    const data = await getTokensOfOwner();

    const items = await Promise.all(
      data.map(async (budStrain) => {
        const meta = await axios.get(API_ROOT + "/bud/" + budStrain._strain);
        return meta;
      })
    );
    return items;
  } else {
    return [];
  }
};

export const getWethBalance = async () => {
  const walletAddress = await getCurrentWalletConnected();
  if (walletAddress && window.currencyContract) {
    try {
      const result = await window.currencyContract?.methods
        .balanceOf(walletAddress)
        .call();
      return result ? result : null;
    } catch {
      console.error("Error while getWethBalance");
    }
  }
};

export const getEthBalance = async () => {
  const walletAddress = await getCurrentWalletConnected();
  if (walletAddress && web3parent) {
    const result = await web3parent.eth.getBalance(walletAddress);
    return result ? result : null;
  }
};

export const getApprovedAllowance = async () => {
  const walletAddress = await getCurrentWalletConnected();
  if (walletAddress && window.currencyContract) {
    try {
      const result = await window.currencyContract?.methods
        .allowance(walletAddress, contractAddress)
        .call();
      return result ? result : null;
    } catch {
      console.error("Error while getApprovedAllowance");
    }
  }
};

export const isLoading = () => {
  return biconomySubject.pipe(withLatestFrom(providerSubject)).asObservable();
};

export const getContractAddress = () => {
  return contractAddress;
};

export const getPrice = () => {
  return web3.utils.fromWei(budPrice.toString());
};
export const getPriceInWei = () => {
  return budPrice;
};
export const formatWei = (wei) => {
  return parseFloat(web3.utils.fromWei(wei)).toPrecision(5);
};

const getSignatureParameters = (signature) => {
  if (!web3.utils.isHexStrict(signature)) {
    throw new Error(
      'Given value "'.concat(signature, '" is not a valid hex string.')
    );
  }
  var r = signature.slice(0, 66);
  var s = "0x".concat(signature.slice(66, 130));
  var v = "0x".concat(signature.slice(130, 132));
  v = web3.utils.hexToNumber(v);
  if (![27, 28].includes(v)) v += 27;
  return {
    r: r,
    s: s,
    v: v,
  };
};
