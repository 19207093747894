import "./minter.scss";
import Button from "@mui/material/Button";
import {
  mint,
  getCurrentWalletConnected,
  isNetworkCorrect,
  lauchWalletConnection,
  getWethBalance,
  estimationInGwei,
  approve,
  approveBridgeTransfer,
  getEthBalance,
  isLoading,
  getApprovedAllowance,
  getPrice,
  getBridgeLimit,
  getAddressLabel,
  forceWalletConnection,
  getSaleState,
  getPriceInWei,
  formatWei,
} from "../../services/interact";
import debounce from "lodash.debounce";
import { useEffect, useState, useRef, useMemo } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import WalletStatus from "../wallet-status/wallet-status";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Loading from "../../components/loading/loading";
import SaleNotActive from "../../components/sale-not-active/sale-not-active";
import { timeInterval, timer } from "rxjs";

const budPrice = getPrice();
const budPriceInWei = getPriceInWei();
const isProd = process.env.REACT_APP_IS_PROD === "true";
var timerId;
var bridgeLimits;

function Minter() {
  const [isSaleActive, setSaleStatus] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [walletAddress, setWallet] = useState(() => "");
  const [bridgeLimits, setBridgeLimits] = useState();
  const [loading, setLoading] = useState(true);

  const [metamaskState, setMetamaskState] = useState(false);
  const [walletConnectionState, setWalletConnectionState] = useState("");
  const [ethOnMainnetState, setEthOnMainnetState] = useState(0);
  const [ethOnPolygonState, setEthOnPolygonState] = useState(0);
  const [approvedAllowanceState, setApprovedAllowanceState] = useState(0);
  const [ethOnMainnet, setEthOnMainnet] = useState(0);
  const [ethOnPolygon, setEthOnPolygon] = useState(0);
  const [approvedAllowance, setApprovedAllowance] = useState(0);
  const [isBridgeRunning, setIsBridgeRunning] = useState(false);
  const [isApprovalRunning, setIsApprovalRunning] = useState(false);
  const [isMintingRunning, setIsMintingRunning] = useState(false);

  /* const [shouldShowOutput, setShouldShowOutput] = useState(false);
  const [output, setOutput] = useState(""); */

  const [bridginglHash, setBridginglHash] = useState("");
  const [spendingApprovalHash, setSpendingApprovalHash] = useState("");
  const [mintingHash, setMintingHash] = useState("");

  const onboarding = useRef();
  const history = useHistory();
  onboarding.current = new MetaMaskOnboarding();
  let isLoadingSubscription;

  // onInit
  useEffect(() => {
    async function fn() {
      isLoadingSubscription = isLoading().subscribe(async () => {
        if (window.provider) {
          /* await setTimeout(() => {
          setSaleStatus(true);
          console.log("status set");
        }, 1200); */
          const address = await getCurrentWalletConnected();
          setWallet(address);
          window.provider.on("accountsChanged", async (accounts) => {
            if (accounts.length > 0) {
              getFlowInformation();
            }
          });
          window.provider.on("chainChanged", async (chainId) => {
            //if (!isNetworkCorrect(chainId)) {
            //  await setWallet("");
            //}
          });
          setBridgeLimits(await getBridgeLimit());
        }
        getFlowInformation();
        setLoading(false);
        onboarding.current.stopOnboarding();
      });
    }
    fn();
  }, []);

  // onDestroy
  useEffect(() => {
    return () => {
      isLoadingSubscription?.unsubscribe();
    };
  }, []);

  const walletConnection = async () => {
    setWallet(await lauchWalletConnection());
  };
  const switchWalletConnection = async () => {
    setWallet(await forceWalletConnection());
  };

  const getMetamaskCondition = async () => {
    setMetamaskState(MetaMaskOnboarding.isMetaMaskInstalled());
  };

  const getWalletConnectionCondition = async () => {
    const connectedWallet = await getCurrentWalletConnected();
    setWalletConnectionState(connectedWallet);
  };

  const getEthOnMainnetCondition = async () => {
    const ethBalance = await getEthBalance();
    if (ethBalance) {
      setEthOnMainnet(ethBalance);
      setEthOnMainnetState(ethBalance >= quantity * budPriceInWei);
    }
  };

  const getEthOnPolygonCondition = async () => {
    const wethBalance = await getWethBalance();
    if (wethBalance) {
      setEthOnPolygon(wethBalance);
      setEthOnPolygonState(wethBalance >= quantity * budPriceInWei);
    }
  };

  const getApprovedAllowanceCondition = async () => {
    const allowance = await getApprovedAllowance();
    if (allowance) {
      setApprovedAllowance(allowance);
      setApprovedAllowanceState(allowance >= quantity * budPriceInWei);
    }
  };

  const getFlowInformation = async () => {
    await getMetamaskCondition();
    await getWalletConnectionCondition();
    await getEthOnMainnetCondition();
    await getEthOnPolygonCondition();
    await getApprovedAllowanceCondition();
  };

  function incrementQuantity() {
    let qnt = quantity;
    if (qnt < 99) {
      setQuantity(qnt + 1);
    } else {
      return;
    }
  }

  function decrementQuantity() {
    let qnt = quantity;
    if (qnt > 1) {
      setQuantity(qnt - 1);
    } else {
      return;
    }
  }

  useEffect(() => {
    debouncedQuantityChange();
  }, [quantity]);

  useEffect(() => {
    getFlowInformation();
  }, [walletAddress]);

  const debouncedQuantityChange = () => {
    clearTimeout(timerId);
    timerId = setTimeout(getFlowInformation, 500);
  };

  const runBridge = async () => {
    const depositTx = await approveBridgeTransfer(quantity);
    setIsBridgeRunning(true);
    setBridginglHash(depositTx.hash);
    // Wait for 1 block confirmation
    const result = await depositTx.wait(1);
    if (result.status > 0) {
      getFlowInformation();
      setTimeout(() => {
        getFlowInformation();
        setIsBridgeRunning(false);
      }, 15000);
    }
  };

  const runSpendingApproval = async () => {
    const depositTx = await approve(quantity);
    setIsApprovalRunning(true);
    depositTx.subscribe((result) => {
      if (result.hash) {
        setSpendingApprovalHash(result.hash);
        setTimeout(() => {
          getFlowInformation();
          setIsApprovalRunning(false);
        }, 15000);
      } else if (result.error) {
        setIsApprovalRunning(false);
      }
    });
  };

  const runMinting = async () => {
    const mintTx = await mint(quantity);
    setIsMintingRunning(true);
    mintTx.subscribe((result) => {
      if (result.hash) {
        setMintingHash(result.hash);
        setTimeout(() => {
          getFlowInformation();
          setIsMintingRunning(false);
        }, 15000);
      } else if (result.error) {
        setIsMintingRunning(false);
      }
    });
  };

  const runOnboard = async () => {
    onboarding.current.startOnboarding();
    window.addEventListener("focus", () => {
      window.location.reload();
    });
  };

  const walletCalllback = async (wall) => {
    setWallet(wall);
  };

  return (
    <div className="Minter">
      {loading ? (
        <Loading />
      ) : (
        <div>
          {isSaleActive ? (
            <div>
              {/* <WalletStatus walletCallback={walletCalllback}></WalletStatus> */}
              {/* <Button variant="contained" onClick={() => onFlowButtonPressed()}>
        {stepsLabels[flowStep]}
      </Button> */}
              <h1>Which strain of Budhead will the plug 🔌 bring?</h1>

              <div className="accordion-container">
                {!metamaskState ? (
                  <Accordion className={metamaskState ? "completed" : ""}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h3>
                        Install Metamask {metamaskState ? <CheckIcon /> : ""}
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        {metamaskState
                          ? "Metamask is installed!"
                          : "Get the metamask extention to your browser se you can easily manage your wallet."}
                      </p>
                      {metamaskState ? (
                        ""
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => runOnboard()}
                        >
                          Install
                        </Button>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
                <Accordion className={walletConnectionState ? "completed" : ""}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {walletConnectionState ? (
                      <h3>
                        Wallet Connected
                        <CheckIcon />
                      </h3>
                    ) : (
                      <h3>Connect Wallet</h3>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      {walletAddress
                        ? `Connected to ${getAddressLabel(walletAddress)}`
                        : "Connect your wallet to the website and change to the correct network."}
                    </p>
                    {walletAddress ? (
                      <Button
                        variant="contained"
                        onClick={() => switchWalletConnection()}
                      >
                        Switch
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => walletConnection()}
                      >
                        Connect
                      </Button>
                    )}
                  </AccordionDetails>
                </Accordion>
                {quantity * budPriceInWei > ethOnMainnet &&
                quantity * budPriceInWei > ethOnPolygon ? (
                  <Accordion className={ethOnMainnetState ? "completed" : ""}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {ethOnMainnetState ? (
                        <h3>
                          Enough ETH on Mainnet <CheckIcon />
                        </h3>
                      ) : (
                        <h3>Deposit ETH on Mainnet</h3>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {ethOnMainnetState ? (
                        <p>
                          You have {formatWei(ethOnMainnet)}ETH on the Ethereum
                          mainnet.
                        </p>
                      ) : (
                        <p>
                          Deposit funds to your wallet using an exchange or
                          directly from another wallet.
                        </p>
                      )}
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open("https://www.moonpay.com/", "_blank")
                        }
                      >
                        MoonPay
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open("https://www.coinbase.com/", "_blank")
                        }
                      >
                        Coinbase
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open("https://www.binance.com/", "_blank")
                        }
                      >
                        Binance
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open("https://www.kraken.com/", "_blank")
                        }
                      >
                        Kraken
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
                <Accordion className={ethOnPolygonState ? "completed" : ""}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    disabled={!ethOnMainnetState && !ethOnPolygonState}
                    id="panel1a-header"
                  >
                    {ethOnPolygonState ? (
                      <h3>
                        Enough ETH on Polygon <CheckIcon />
                      </h3>
                    ) : (
                      <h3>Deposit ETH on Polygon</h3>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {ethOnPolygon > 0 ? (
                      <p>
                        You have {formatWei(ethOnPolygon)}ETH on the Polygon.
                      </p>
                    ) : (
                      <p>Bridge your ETH to the Polygon network.</p>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => runBridge()}
                      disabled={
                        !ethOnMainnetState ||
                        !(
                          parseFloat(bridgeLimits?.minDepositAmount) <=
                            quantity * budPrice &&
                          quantity * budPrice <=
                            parseFloat(bridgeLimits?.maxDepositAmount)
                        )
                      }
                    >
                      {ethOnPolygon > 0 ? "Bridge more" : "Bridge"}
                    </Button>
                    {bridginglHash ? (
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open(
                            `https://${
                              isProd ? "" : "goerli."
                            }etherscan.io/tx/${bridginglHash}`,
                            "_blank"
                          )
                        }
                      >
                        View Transaction
                        <span className="outer">
                          <img src="images/loading-accent.png" />
                        </span>
                      </Button>
                    ) : (
                      ""
                    )}
                    {isBridgeRunning ? (
                      <span className="outer">
                        <img src="images/loading-accent.png" />
                      </span>
                    ) : (
                      ""
                    )}
                    {/* {allowanceApprovalHash ? (
                      <Button variant="contained" onClick={() => runBridge()}>
                        Cancel Bridge
                      </Button>
                    ) : (
                      ""
                    )} */}
                    {quantity * budPrice < bridgeLimits?.minDepositAmount ? (
                      <p className="error">
                        Minimum bridge amount is{" "}
                        {bridgeLimits?.minDepositAmount}
                        ETH for Hyphen bridge. You can use the{" "}
                        <a
                          href="https://wallet.polygon.technology/bridge"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Polygon Bridge
                        </a>{" "}
                        or increase the quantity to at least{" "}
                        {Math.ceil(bridgeLimits?.minDepositAmount / budPrice)}{" "}
                        buds.
                      </p>
                    ) : (
                      ""
                    )}
                    {quantity * budPrice > bridgeLimits?.maxDepositAmount ? (
                      <p className="error">
                        Max bridge amount is {bridgeLimits?.maxDepositAmount}
                        ETH. Decrease the quantity of buds.
                      </p>
                    ) : (
                      ""
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={approvedAllowanceState ? "completed" : ""}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    disabled={!ethOnPolygonState}
                    id="panel1a-header"
                  >
                    {approvedAllowanceState ? (
                      <h3>
                        Enough Allowance Approved <CheckIcon />
                      </h3>
                    ) : (
                      <h3>Approve Spending Allowance</h3>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {approvedAllowance > 0 ? (
                      <p>
                        You have approved an allowance of{" "}
                        {formatWei(approvedAllowance)}ETH for this sesh.
                      </p>
                    ) : (
                      <p>Approve the spending of the cost of your Budheads.</p>
                    )}
                    <Button
                      variant="contained"
                      disabled={!ethOnPolygonState}
                      onClick={() => runSpendingApproval()}
                    >
                      Approve
                    </Button>
                    {spendingApprovalHash ? (
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open(
                            `https://${
                              isProd ? "" : "mumbai."
                            }polygonscan.com/tx/${spendingApprovalHash}`,
                            "_blank"
                          )
                        }
                      >
                        View Transaction
                      </Button>
                    ) : (
                      ""
                    )}
                    {isApprovalRunning ? (
                      <span className="outer">
                        <img src="images/loading-accent.png" />
                      </span>
                    ) : (
                      ""
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    disabled={!approvedAllowanceState}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>Mint</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      Let's mint{" "}
                      {quantity > 1
                        ? `those ${quantity} buds`
                        : `that ${quantity} bud`}
                      ? You can change the quantity below 👇
                    </p>
                    <Button
                      variant="contained"
                      disabled={!approvedAllowanceState}
                      onClick={() => runMinting()}
                    >
                      Mint
                    </Button>
                    {mintingHash ? (
                      <Button
                        variant="contained"
                        onClick={() =>
                          window.open(
                            `https://${
                              isProd ? "" : "mumbai."
                            }polygonscan.com/tx/${mintingHash}`,
                            "_blank"
                          )
                        }
                      >
                        View Transaction
                      </Button>
                    ) : (
                      ""
                    )}
                    {isMintingRunning ? (
                      <span className="outer">
                        <img src="images/loading-accent.png" />
                      </span>
                    ) : mintingHash ? (
                      <Button
                        variant="contained"
                        onClick={() => history.push("/my-buds")}
                      >
                        View My Buds
                      </Button>
                    ) : (
                      ""
                    )}
                    {quantity * budPriceInWei > approvedAllowance ? (
                      <p className="error">Not enough approved allowance</p>
                    ) : (
                      ""
                    )}
                    {quantity * budPriceInWei > ethOnPolygon ? (
                      <p className="error">Not enough ETH balance on Polygon</p>
                    ) : (
                      ""
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary className="quantity-header">
                    <h3>Quantity</h3>
                    <div className="quantity-selector">
                      <RemoveIcon onClick={() => decrementQuantity()} />
                      <h3>{quantity}</h3>
                      <AddIcon onClick={() => incrementQuantity()} />
                    </div>
                    <h3>{(quantity * budPrice).toFixed(4)}ETH</h3>
                  </AccordionSummary>
                </Accordion>
                {/* <button onClick={() => setShouldShowOutput(!shouldShowOutput)}>
                  VIEW DEBUGGING
                </button>
                {shouldShowOutput ? <div>{output}</div> : ""} */}
              </div>
            </div>
          ) : (
            <SaleNotActive />
          )}
        </div>
      )}
    </div>
  );
}

export default Minter;
