import "./faq.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Faq() {
  return (
    <div className="Faq">
      <h2>FAQ</h2>

      <div className="accordion-container">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>How do I get a Budhead?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Just click the button at the beginning of our website and follow
              the steps! Each Budhead costs only 0.0042 ETH.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>Why is my Budhead not appearing on OpenSea?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              OpenSea can take longer to process the buying of your Budhead, but
              don’t worry, your Budheads will appear on your OpenSea profile
              soon!
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Faq;
