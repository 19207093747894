import './mint.scss';
import Minter from '../../components/minter/minter';

function Mint() {

  return (
    <div className="Mint">
        <Minter></Minter>
    </div>
  );
}

export default Mint;
