import "./sale-not-active.scss";
import Button from "@mui/material/Button";

function SaleNotActive() {
  return (
    <div className="SaleNotActive">
      <div className="upper-block">
        <h1>Not time to meet your buds yet</h1>
        <img src="images/sun.png" />
      </div>
      <Button
        variant="contained"
        className="cta"
        onClick={() => window.open(`https://discord.gg/hmSFmj6a85`, "_blank")}
      >
        <h2>Check out the Discord!</h2>
      </Button>
    </div>
  );
}

export default SaleNotActive;
