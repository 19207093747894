import "./no-buds.scss";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

function NoBuds() {
  const history = useHistory();
  return (
    <div className="Loading">
      <div className="upper-block">
        <h1>Where are all the buds?</h1>
        <img src="images/ethereum.png" />
      </div>
      <Button
        variant="contained"
        className="cta"
        onClick={() => history.push("/mint")}
      >
        <h2>Go get yourself some!</h2>
      </Button>
    </div>
  );
}

export default NoBuds;
