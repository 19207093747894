import "./we-are-a-movement.scss";

function WeAreAMovement() {
  return (
    <div className="WeAreAMovement">
      <h2>We are not just a collection. We are a movement.</h2>
      <div className="images-container">
        <img src="images/budheads-sticker-1.jpeg" />
        <img src="images/budheads-tattoo.jpeg" />
        <img src="images/budheads-sticker-2.jpeg" />
        <img src="images/budheads-trip.gif" />
      </div>
      <p>
        Our motto is underpromising and overdelivering. We created a concept
        called Weekly Airdrops, where we will reward our most engaged and
        beloved Budheads owners.
      </p>
      <p>
        For example, in a given week, users with at least 5 Budheads will be
        airdropped Special Edition Budheads. Every week we will keep making
        contests like this, changing the requirements each week. Here are some
        examples of Special Edition Budheads:
      </p>
      <div className="images-container">
        <img src="images/special-1.png" />
        <img src="images/special-2.png" />
        <img src="images/special-3.png" />
        <img src="images/special-4.png" />
      </div>
    </div>
  );
}

export default WeAreAMovement;
