import Home from "./containers/home/home";
import MyBuds from "./containers/my-buds/my-buds";
import Mint from "./containers/mint/mint";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { setupContract, isLoading, setupProvider } from "./services/interact";
import { useEffect } from "react";

let biconomySubscription;

function App() {
  // onInit
  useEffect(() => {
    async function fn() {
      await setupProvider();
      biconomySubscription = isLoading().subscribe(async() => await setupContract());
    }
    fn();
  }, []);

  // onDestroy
  useEffect(() => {
    return () => {
      biconomySubscription?.unsubscribe();
    };
  }, []);

  return (
    <main className="App">
      <Header></Header>
      <div className="router-outlet">
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/my-buds" component={MyBuds} />
          <Route path="/mint" component={Mint} />
        </Switch>
      </div>
      <Footer></Footer>
    </main>
  );
}

export default App;
