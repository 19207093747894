import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import "./header.scss";
import { useHistory } from "react-router-dom";
import {
  getContractAddress
} from "../../services/interact";

const isProd = process.env.REACT_APP_IS_PROD === "true";

const Header = () => {
  const history = useHistory();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const linkClicked = (itemName) => (event) => {
    setTimeout(() => {
      switch (itemName) {
        case "HOME":
          history.push("/");
          break;
        case "MINT":
          history.push("/mint");
          break;
        case "MY BUDS":
          history.push("/my-buds");
          break;
        case "OPENSEA":
            window.open(`https://${isProd?'':'testnets.'}opensea.io/collection/budheads-garden`, "_blank");
          break;
        default:
          break;
      }
    }, 200);
  };

  const headersList = [
    "HOME",
    "MINT",
    "MY BUDS",
    "OPENSEA"
  ];

  const list = (anchor) => (
    <div
      className="menu-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {headersList.map((text, index) => (
          <ListItem button key={text} onClick={linkClicked(text)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className="header-container">
      <section className="list-menu">
        <List>
          {headersList.map((text, index) => (
            <ListItem button key={text} onClick={linkClicked(text)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </section>
      <section className="hamburger-menu">
        <Button className="menu-button" onClick={toggleDrawer("right", true)}>
          <MenuIcon />
        </Button>
      </section>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

export default Header;
