import './accent-separator.scss';

function AccentSeparator() {
  return (
    <div className="AccentSeparator">
        <img src="images/separator.png"/>
    </div>
  );
}

export default AccentSeparator;
