import './home.scss';
import Banner from '../../components/banner/banner';
import HowMuch from '../../components/how-much/how-much';
import Pricing from '../../components/pricing/pricing';
import AccentSeparator from '../../components/accent-separator/accent-separator';
import JoinDiscord from '../../components/join-discord/join-discord';
import Minter from '../../components/minter/minter';
import Faq from '../../components/faq/faq';
import WeAreAMovement from '../../components/we-are-a-movement/we-are-a-movement';
import WhatIsBudheads from '../../components/what-is-budheads/what-in-budheads';
import Roadmap from '../../components/roadmap/roadmap';
import Team from '../../components/team/Team';
import BigHomeCta from '../../components/big-home-cta/big-home-cta';

function Home() {

  return (
    <div className="Home">
        <Banner></Banner>
        <BigHomeCta></BigHomeCta>
        <WhatIsBudheads></WhatIsBudheads>
        <HowMuch></HowMuch>
        <AccentSeparator></AccentSeparator>
        {/* <Pricing></Pricing> */}
        <WeAreAMovement></WeAreAMovement>
        <Team></Team>
        <AccentSeparator></AccentSeparator>
        <Roadmap></Roadmap>
        <Faq></Faq>
        <JoinDiscord></JoinDiscord>
    </div>
  );
}

export default Home;
