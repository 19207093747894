import './banner.scss';

function Banner() {
  return (
    <div className="Banner">
        <img src="images/banner.png"/>
    </div>
  );
}

export default Banner;
