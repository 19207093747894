import "./join-discord.scss";

function JoinDiscord() {
  return (
    <div className="JoinDiscord">
      <h2>
        Join our Discord, get high with other Budlovers, and discover how you
        can win some Budheads for free!
      </h2>
      <a className="discord-link" href="https://discord.gg/hmSFmj6a85" target="_blank" rel="noreferer">Join Discord</a>
    </div>
  );
}

export default JoinDiscord;
